export function lockBody() {
  document.body.style.paddingRight = `${
    window.innerWidth - document.documentElement.clientWidth
  }px`;
  document.body.style.height = "100%";
  document.body.style.overflow = "hidden";
}

export function unlockBody() {
  document.body.style.height = "";
  document.body.style.overflow = "";
  document.body.style.paddingRight = "";
}

export function flattenObject(ob: any): any {
  // The object which contains the
  // final result
  const result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObject(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[`${i}.${j}`] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
}
